import { createContext, useContext } from 'react'
import { GetCurrentGlobalUser } from '__generated__/types'

export type UserSessionContextData = {
  authenticated: boolean | undefined
  onLoginSuccess: () => void
  forceLogout: (redirectTo?: string) => Promise<void>
  me: GetCurrentGlobalUser.Me | undefined
  currentUserId: string | undefined
}

const UserSessionContext = createContext<UserSessionContextData>({
  authenticated: undefined,
  onLoginSuccess: () => {},
  forceLogout: () => Promise.resolve(),
  me: undefined,
  currentUserId: undefined
})

export function useUserSessionContext(): UserSessionContextData {
  return useContext(UserSessionContext)
}

export default UserSessionContext
