import { createContext, useContext } from 'react'
import { PortalMerchantLocation } from '__generated__/types'

export type PortalLocationContextValue = {
  merchantLocation: PortalMerchantLocation.MerchantLocation | null | undefined
}

const PortalLocationContext = createContext<PortalLocationContextValue>({
  merchantLocation: null
})

export function usePortalLocation(): PortalLocationContextValue {
  return useContext(PortalLocationContext)
}

export default PortalLocationContext
