type WebViewMessage = {
  action: string
  data?: Record<string, any>
}

type WindowType = Window & typeof globalThis

export const isNestedWebView = (
  window: WindowType
): window is WindowType & {
  ReactNativeWebView: WindowType
} => {
  return Object.prototype.hasOwnProperty.call(window, 'ReactNativeWebView')
}

export const sendMessage = (payload: WebViewMessage): boolean => {
  if (isNestedWebView(window)) {
    if (!payload.data) {
      payload.data = {}
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(payload), '*')
    return true
  }
  return false
}
