import EventTarget from '@ungap/event-target'

// The exported webSocket variable will be defined once the socket connection
// has been opened, and it will become undefined if the socket connection is
// intentionally closed.
export let webSocket: WebSocket | undefined

export const WebSocketEventBus =
  typeof document !== 'undefined' ? new EventTarget() : undefined

export enum WebSocketEvent {
  Opened = 'opened',
  Connected = 'connected',
  Closed = 'closed',
  Disconnected = 'disconnected'
}

let socketConnected = false

/**
 * An interface for event listeners passed through from the GraphQL over
 * WebSocket client. The events are rebroadcast through an event bus so they can
 * be monitored in different application modules.
 */
const WebSocketMonitor = {
  opened: (socket: unknown) => {
    webSocket = socket as WebSocket
    WebSocketEventBus?.dispatchEvent(new Event(WebSocketEvent.Opened))
  },
  connected: () => {
    socketConnected = true
    WebSocketEventBus?.dispatchEvent(new Event(WebSocketEvent.Connected))
  },
  closed: (event: unknown) => {
    if (event instanceof CloseEvent && event.code === 1000) {
      // The WebSocket connection was closed normally because there are no
      // more active GraphQL subscriptions
      webSocket = undefined
      WebSocketEventBus?.dispatchEvent(new Event(WebSocketEvent.Closed))
    } else {
      // Check `socketConnected` so this event is only dispatched once,
      // since the closed event handler is called multiple times after the
      // socket loses connection.
      if (socketConnected) {
        socketConnected = false
        WebSocketEventBus?.dispatchEvent(new Event(WebSocketEvent.Disconnected))
      }
    }
  }
}

export default WebSocketMonitor
