import {
  ApolloClient,
  ApolloClientOptions,
  createHttpLink,
  split,
  ApolloLink
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { Auth } from '@aws-amplify/auth'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import WebSocketMonitor from 'models/WebSocketMonitor'
import { logger } from 'utils/logger'

const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT!

export const wsEndpoint = API_ENDPOINT.replace('http', 'ws')

const httpLink = createHttpLink({
  uri: `${API_ENDPOINT}/graph`
})

const batchHttpLink = new BatchHttpLink({
  uri: `${API_ENDPOINT}/graph`,
  batchInterval: 50,
  batchMax: 20
})

const splitHttpLink = split(
  (operation) => operation.getContext().batch === true,
  batchHttpLink,
  httpLink
)

const getAccessToken = async () => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    return token
  } catch (error) {
    return null
  }
}

const authLink = setContext(async (_, { headers = {} }) => {
  const token = await getAccessToken()
  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  return { headers }
})

const errorLink = onError((error) => {
  const { graphQLErrors } = error
  if (graphQLErrors) {
    logger.error(error)
  }
})

const links = ApolloLink.from([errorLink, authLink, splitHttpLink])

let apolloLink: ApolloLink

// WebSocket is not defined in the node environment, so we must wait to create
// the apollo link until this code is hydrated in the browser.
if (typeof document !== 'undefined') {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${wsEndpoint}/graphql-ws`,
      connectionParams: async () => {
        const token = await getAccessToken()
        return { token }
      },
      // If the WebSocket closes due to a 'CloseEvent', it should try to
      // reconnect automatically. However, this was not the behavior we
      // experienced. So we tell the client to try to reconnect no matter the
      // error reason.
      shouldRetry: () => true,
      // An arbitrary high number of attempts, with exponential backoff
      retryAttempts: 10000,
      on: {
        opened: WebSocketMonitor.opened,
        connected: WebSocketMonitor.connected,
        closed: WebSocketMonitor.closed
      }
    })
  )

  // Splits Apollo requests to the correct transport (http/ws) based on the operation definition
  // https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    links
  )

  apolloLink = splitLink
}

export const createApolloClient = (
  options: ApolloClientOptions<unknown>
): ApolloClient<unknown> => {
  const defaultApolloClientOptions: Partial<ApolloClientOptions<unknown>> = {
    link: apolloLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      }
    }
  }

  return new ApolloClient({
    ...defaultApolloClientOptions,
    ...options
  })
}
