import { useCallback, useEffect, useRef, useMemo } from 'react'
import qs from 'qs'
import { AmplitudeClient } from 'amplitude-js'
import TrackingContext, {
  TrackingClient
} from 'contexts/Tracking/TrackingContext'
import { useOpsLocation } from 'apps/Ops/contexts/OpsLocation/OpsLocationContext'
import { usePortalLocation } from 'apps/Portal/providers/PortalLocation/PortalLocationContext'
import { useDinerLocation } from 'apps/Diner/contexts/DinerLocation/DinerLocationContext'

export let amplitude!: AmplitudeClient

if (typeof window !== 'undefined') {
  amplitude = require('amplitude-js').getInstance()
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!, undefined, {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    includeFbclid: true
  })
}

type CachedEvent = { event: string; data: Record<string, unknown> }

export const tracking: TrackingClient = {
  log: (event, data) => {
    amplitude.logEvent(event, data)
  }
}

/**
 * The TrackingProvider is an interface for sending events to amplitude.
 *
 * Many of our tracking events require a merchantLocationName field, so this
 * provider stores a cache of merchant location names and always includes the
 * name if a tracking event includes a merchantLocationId field in the data.
 *
 * It also stores the utm params that were in the initial page url, so those are
 * sent with all future tracking events in the session.
 */
export default function TrackingProvider({
  children
}: {
  children: JSX.Element
}): JSX.Element {
  const utmParams = useRef<Record<string, string>>({})
  // A cache with keys of a merchantLocationId and values of the
  // merchantLocationName.
  const locationsCache = useRef<Record<string, string>>({})
  const eventCache = useRef<Array<CachedEvent>>([])
  const { merchantLocation: opsLocation } = useOpsLocation()
  const { merchantLocation: portalLocation } = usePortalLocation()
  const { merchantLocation: dinerLocation } = useDinerLocation()

  useEffect(() => {
    // Caches all the utm params that are in the initial page url and includes
    // them in all tracking events for this session.
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    Object.keys(queryParams).forEach((key) => {
      if (key.startsWith('utm')) {
        const value = queryParams[key]
        if (typeof value === 'string') {
          utmParams.current[key] = value
        }
      }
    })
  }, [])

  const sendCachedEventsForLocation = useCallback(
    (merchantLocation: { id: string; name: string }) => {
      const updatedCache: Array<CachedEvent> = []
      eventCache.current.forEach(({ event, data }) => {
        if (data.merchantLocationId === merchantLocation.id) {
          // This cached tracking event was created for the merchantLocation,
          // and it can be sent with the merchantLocationName now.
          tracking.log(event, {
            ...data,
            merchantLocationName: merchantLocation.name
          })
        } else {
          // This cached tracking event does not belong to this
          // merchantLocation, so it needs to be retained in the cache.
          updatedCache.push({ event, data })
        }
      })
      // Reset the eventCache with the events that have not yet been sent.
      eventCache.current = updatedCache
    },
    []
  )

  useEffect(() => {
    // When a merchant location is fetched in one of the location providers, add
    // it's name to the cache and publish all cached events for that location.
    if (opsLocation) {
      locationsCache.current[opsLocation.id] = opsLocation.name
      sendCachedEventsForLocation(opsLocation)
    } else if (portalLocation) {
      locationsCache.current[portalLocation.id] = portalLocation.name
      sendCachedEventsForLocation(portalLocation)
    } else if (dinerLocation) {
      locationsCache.current[dinerLocation.id] = dinerLocation.name
      sendCachedEventsForLocation(dinerLocation)
    }
  }, [sendCachedEventsForLocation, opsLocation, portalLocation, dinerLocation])

  const trackingClient: TrackingClient = useMemo(() => {
    return {
      log: (event, data = {}) => {
        // Merge the cached utm params with the event data
        data = Object.assign(data, utmParams.current)
        const { merchantLocationId } = data

        if (typeof merchantLocationId === 'string') {
          if (locationsCache.current[merchantLocationId]) {
            // The merchantLocationName already exists in the cache, we can log
            // this event with the name included as an additional property.
            tracking.log(event, {
              ...data,
              merchantLocationName: locationsCache.current[merchantLocationId]
            })
          } else {
            // The merchantLocationId was included in the data, but the
            // merchantLocationName does not exist in the cache yet. We need to
            // store this event and log it after the merchantLocation has finished
            // loading.
            eventCache.current.push({ event, data })
          }
        } else {
          // If no merchantLocationId was included in the data, we can log this
          // event right away.
          tracking.log(event, data)
        }
      }
    }
  }, [])

  const contextValue = useMemo(() => {
    return { tracking: trackingClient, utmParams: utmParams.current }
  }, [trackingClient])

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  )
}
