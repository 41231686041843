import { createContext, useContext } from 'react'

export interface TrackingClient {
  log: (event: string, data?: Record<string, unknown>) => void
}

export type TrackingContextValue = {
  tracking: TrackingClient
  utmParams: Record<string, string>
}

const TrackingContext = createContext<TrackingContextValue>({
  tracking: {
    log: () => {}
  },
  utmParams: {}
})

export function useTracking(): TrackingContextValue {
  return useContext(TrackingContext)
}

export default TrackingContext
