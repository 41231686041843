import { createContext, useContext } from 'react'
import { GetMerchantLocation } from '__generated__/types'
import { ApolloError } from '@apollo/client'

export type DinerLocationContextValue = {
  merchantLocation: GetMerchantLocation.MerchantLocation | null | undefined
  error: ApolloError | undefined
}

const DinerLocationContext = createContext<DinerLocationContextValue>({
  merchantLocation: null,
  error: undefined
})

export function useDinerLocation(): DinerLocationContextValue {
  return useContext(DinerLocationContext)
}

export default DinerLocationContext
