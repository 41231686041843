import { createContext, useContext } from 'react'
import { GetOpsLocation } from '__generated__/types'

export type OpsLocationContextValue = {
  merchantLocation: GetOpsLocation.MerchantLocation | null | undefined
}

const OpsLocationContext = createContext<OpsLocationContextValue>({
  merchantLocation: null
})

export function useOpsLocation(): OpsLocationContextValue {
  return useContext(OpsLocationContext)
}

export default OpsLocationContext
