export const BELUGA_INTERNAL = [
  'KDWznR', // Jim Yang
  'JnERD2', // Devin Guan
  'k3vPn1', // Ben Hansen
  'an78DZ', // Edwin Chiu
  'DPl6jD', // xdays
  'nNqvkQ', // Wally
  'D0aaq3', // Lucas Alves
  'DYPJYn', // Stephen Cheng
  'neRyBD', // Dave Rodriguez
  'KDWm6Q', // Molly Gilbert
  'QzxvrK' // Lisa Cummins
]

export const canHaveFullStory = (userId: string): boolean => {
  return !BELUGA_INTERNAL.includes(userId)
}

export const userDisplayName = (
  user:
    | {
        cognitoUsername: string
        firstName?: string | null | undefined
        fullName?: string | null | undefined
      }
    | null
    | undefined
): string | undefined => {
  return user?.firstName || user?.fullName || user?.cognitoUsername
}

export async function loadImage(src: string): Promise<HTMLImageElement | null> {
  return new Promise((resolve) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', () => {
      resolve(null)
    })
    image.src = src
  })
}
