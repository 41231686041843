import { Auth } from '@aws-amplify/auth'
import { uuid } from 'utils/crypto'

// The alias can be a username, email, or phone number. Auth.confirmSignUp
// checks to see if a user exists with that username, or has that email/phone
// verified. We have to make this check before calling Auth.signUp, because our
// pre-sign-up lambda trigger auto verifies phone numbers, allowing users to
// sign in without going through another verification flow. And if a phone is
// auto verified while already existing on another user, that other user gets
// their phone unverified.
export async function aliasExistsInUserPool(alias: string): Promise<boolean> {
  // Purposely invalid code
  const code = '123'
  try {
    await Auth.confirmSignUp(alias, code, {
      forceAliasCreation: false
    })
    // We will never reach this line because we know the code is incorrect,
    // which will throw an error.
    return true
  } catch (error) {
    if ((error as { code: string }).code === 'UserNotFoundException') {
      // A user was not found with this alias, meaning it is available to be
      // used to sign up.
      return false
    }
    return true
  }
}

export function generatePassword() {
  return uuid()
}

function getRandomString() {
  return uuid().split('-')[0]
}

export function generateUsername() {
  return 'user' + getRandomString()
}

export function generateGuestUsername() {
  return 'guest' + getRandomString()
}
