import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Auth } from '@aws-amplify/auth'
import type { CognitoUserSession } from 'amazon-cognito-identity-js'
import { useGetCurrentGlobalUserQuery } from '__generated__/types'
import * as FullStory from '@fullstory/browser'
import UserSessionContext from 'contexts/UserSession/UserSessionContext'
import { canHaveFullStory } from 'utils'
import { amplitude } from 'contexts/Tracking/TrackingProvider'
import { useApolloClient } from '@apollo/client'
import { logger } from 'utils/logger'
import { useRouter } from 'next/router'
import { useTracking } from 'contexts/Tracking/TrackingContext'
import { generateGuestUsername, generatePassword } from 'utils/session'
import { sendMessage } from 'utils/webview'

async function createGuestSession(): Promise<CognitoUserSession | null> {
  try {
    const username = generateGuestUsername()
    const password = generatePassword()
    await Auth.signUp({ username, password })
    const { signInUserSession } = await Auth.signIn({ username, password })
    return signInUserSession
  } catch (error) {
    if ((error as Error)?.message !== 'Network error') {
      logger.error(error)
    }
    return null
  }
}

async function getCurrentSession() {
  try {
    return await Auth.currentSession()
  } catch (error) {
    return null
  }
}

export default function UserSessionProvider({
  children,
  shouldCreateGuestSession = false
}: {
  children: JSX.Element
  shouldCreateGuestSession?: boolean
}): JSX.Element {
  const client = useApolloClient()
  const [authenticated, setAuthenticated] = useState<boolean>()
  const [unableToCreateGuestSession, setUnableToCreateGuestSession] =
    useState(false)

  const { data } = useGetCurrentGlobalUserQuery({
    skip: !authenticated,
    onCompleted: (data) => {
      const user = data.me
      amplitude.setUserId(user.uid)
      amplitude.setUserProperties({
        cognitoUsername: user.cognitoUsername,
        handle: user.handle,
        userMode: user.userMode
      })
      sendMessage({ action: 'set_user_id', data: { uid: user.uid } })
      logger.setUserProperties({
        userId: user.id,
        uid: user.uid,
        cognitoUsername: user.cognitoUsername,
        handle: user.handle,
        userMode: user.userMode
      })
    }
  })
  const me = data?.me
  const currentUserId = me?.id

  useEffect(() => {
    async function getSession() {
      const currentSession = await getCurrentSession()
      if (currentSession) {
        setAuthenticated(true)
      } else if (shouldCreateGuestSession) {
        // In the Diner app, automatically create a new guest user if there is
        // no previous session.
        const newSession = await createGuestSession()
        if (newSession) {
          setAuthenticated(true)
        } else {
          setAuthenticated(false)
          // If there is no signed in user, and we were also not able to create
          // a session for a new guest user, then show a fallback UI with a
          // reload button because the app will be unusable.
          setUnableToCreateGuestSession(true)
        }
      } else {
        setAuthenticated(false)
      }
    }
    getSession()
  }, [shouldCreateGuestSession])

  useEffect(() => {
    if (
      currentUserId &&
      process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID &&
      canHaveFullStory(currentUserId)
    ) {
      try {
        FullStory.init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID })
      } catch (error) {}
    }
  }, [currentUserId])

  const forceLogout = useCallback(
    async (redirectTo?: string) => {
      await Auth.signOut()
      client.resetStore()
      window.location.href = redirectTo || '/login'
    },
    [client]
  )

  const onLoginSuccess = useCallback(() => {
    setAuthenticated(true)
  }, [])

  const contextValue = useMemo(() => {
    return {
      authenticated,
      currentUserId,
      forceLogout,
      me,
      onLoginSuccess
    }
  }, [authenticated, currentUserId, forceLogout, me, onLoginSuccess])

  return (
    <UserSessionContext.Provider value={contextValue}>
      {unableToCreateGuestSession ? <GuestSessionErrorFallback /> : children}
    </UserSessionContext.Provider>
  )
}

function GuestSessionErrorFallback() {
  const router = useRouter()
  const { tracking } = useTracking()

  function onClickReload() {
    tracking.log('WEB:CLICK_RELOAD_GUEST_SESSION_ERROR')
    router.reload()
  }

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      height="100vh"
      bgcolor="black"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography color="white" mb={2}>
        Oops, we were unable to connect to the server.
      </Typography>
      <Button variant="contained" onClick={onClickReload}>
        Reload
      </Button>
    </Box>
  )
}
